import React, { useState } from 'react';
import { Button, message, Modal, Upload } from "antd";
import { FaPlus } from "react-icons/fa";
import { findPlantById, updatePlantPhotos } from "../../services/PlantService";
import Search from "antd/es/input/Search";
import '../../styles/EditPhotosScreen.scss';
import { convertOneSinglePhoto, getAllPhotoIndexAndReturnNext } from "../../utils/plantObjUtils";

const getBase64 = ( file ) =>
    new Promise( ( resolve, reject ) => {
        const reader = new FileReader();
        reader.readAsDataURL( file );
        reader.onload = () => resolve( reader.result );
        reader.onerror = ( error ) => reject( error );
    } );

const EditPhotosScreen = () => {


    const [ previewOpen, setPreviewOpen ] = useState( false );
    const [ previewImage, setPreviewImage ] = useState( '' );
    const [ previewTitle, setPreviewTitle ] = useState( '' );
    const [ plant, setPlant ] = useState( {} );
    const [ fileList, setFileList ] = useState( [] );
    const [ filesToRemove, setFilesToRemove ] = useState( [] );
    const [ filesToSave, setFilesToSave ] = useState( [] );


    const handleCancel = () => setPreviewOpen( false );
    const handlePreview = async ( file ) => {
        if ( !file.url && !file.preview ) {
            file.preview = await getBase64( file.originFileObj );
        }

        setPreviewImage( file.url || file.preview );
        setPreviewOpen( true );
        setPreviewTitle( file.name || file.url.substring( file.url.lastIndexOf( '/' ) + 1 ) );
    };
    const handleChange = ( photoChanged ) => {

        if ( photoChanged.file.status === 'removed' && !photoChanged.file.percent ) {
            setFilesToRemove( prevState => prevState.length === 0 ? [ photoChanged.file ] : [ ...prevState, photoChanged.file ] );
            setFilesToSave( prevState => prevState.length === 0 ? [] : prevState.map( file => {
                return {
                    url: file.url,
                    index: getAllPhotoIndexAndReturnNext( plant.photos.filter( ( photo ) => !filesToRemove.map( file => file.url ).includes( photo ) ), filesToSave )
                };
            } ) )
        } else if ( photoChanged.file.status === 'removed' && photoChanged.file.percent ) {
            setFilesToSave( prevState => prevState.filter( photo => photo.url.uid !== photoChanged.file.uid ) );
        }
        if ( photoChanged.file.status === 'done' ) {
            setFilesToSave( prevState => prevState.length === 0 ?
                [ {
                    url: photoChanged.file,
                    index: getAllPhotoIndexAndReturnNext( plant.photos.filter( ( photo ) => !filesToRemove.map( file => file.url ).includes( photo ) ), filesToSave )
                } ] :
                [ ...prevState, {
                    url: photoChanged.file,
                    index: getAllPhotoIndexAndReturnNext( plant.photos.filter( ( photo ) => !filesToRemove.map( file => file.url ).includes( photo ) ), filesToSave )
                } ] );
        }
        setFileList( photoChanged.fileList )
    };
    const uploadButton = (
        <div>
            <FaPlus/>
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const getPlantToUpdate = async ( plantId ) => {
        setFilesToSave( [] );
        setFilesToRemove( [] );
        const result = await findPlantById( plantId.trim() );
        if (!result?.ok) {
            message.warning("No se pudo encontrar una planta con ese ID");
            return;
        }
        setPlant( result.plant );
        setFileList( result.plant.photos.map( ( photo ) => {
            return {
                uid: photo,
                url: photo
            }
        } ) );
    }

    const updatePhotosFunc = async () => {
        const obj = {
            plantId: plant._id,
            filesToSave: await Promise.all( filesToSave.map( async ( file ) => {
                return {
                    index: file.index,
                    data: await convertOneSinglePhoto( file.url ),
                }
            } ) ),
            filesToRemove,
            filesToKeep: plant.photos.filter( ( photo ) => !filesToRemove.map( file => file.url ).includes( photo ) )

        }
        if ( obj.filesToSave.length === 0 && obj.filesToRemove.length === 0 ) {
            message.info( "No hay cambios que realizar" );
            return;
        }
        if ( obj.filesToSave.length + obj.filesToKeep.length < 3 ) {
            message.warning( "El minimo de fotos posible es 3" );
            return;
        }
        await updatePlantPhotos( obj );
        return message.success( "Fotos actualizadas correctamente", 4 );
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: 'center' }}>
            <Search placeholder="Identificación Planta" onSearch={getPlantToUpdate}
                    style={{ width: '50%', margin: '40px 0', alignSelf: 'middle' }}
                    enterButton="Buscar Planta" size="large"/>
            <Upload
                rootClassName='upload-update-photos'
                action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                listType="picture-card"
                customRequest={( { onSuccess } ) => onSuccess( 'Ok' )}
                className='edit-photos-plant'
                fileList={fileList}
                onPreview={handlePreview}
                maxCount={4}
                onChange={handleChange}
                disabled={fileList.length === 0}
            >
                {fileList.length >= 8 ? null : uploadButton}
            </Upload>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img
                    alt="example"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage}
                />
            </Modal>
            <Button onClick={updatePhotosFunc} type='primary' style={{ height: 46, marginTop: 16 }}>Actualizar
                Fotos</Button>
        </div>
    );
};


export default EditPhotosScreen;
