import React from 'react';
import { Avatar, Card, Skeleton } from "antd";
import Meta from "antd/es/card/Meta";
import PropTypes from "prop-types";

const FakePlantGridComponent = ( { isLoading } ) => {
    return (
        <Card
        >
            <Skeleton loading={isLoading} avatar active>
                <Meta
                    avatar={<Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=2" />}
                    title="Card title"
                    description="This is the description"
                />
            </Skeleton>
        </Card>
    );
};

FakePlantGridComponent.propTypes = {
    isLoading: PropTypes.bool.isRequired,
};
export default FakePlantGridComponent;
