import React, { useEffect, useState } from 'react';
import PlantCardComponent from "../Shared/PlantCardComponent";
import { Carousel, Col, Row } from "antd";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from 'react-router-dom';
import EmptyComponent from "../Shared/EmptyComponent";
import { checkSelectorScreenPlant, validateIfNeedToReloadPlants } from "../utils/PlantCardUtils";
import { NEW_PLANTS } from "../constants/Constants";
import '../styles/BasePlantGridScreen.scss';
import FakePlantGridComponent from "../Shared/FakePlantGridComponent";
import { getPlantsThunk } from "../store/plant/plantThunks";
import { getUriNameByPathname } from "../utils/routerUtils";

const BasePlantGridScreen = ( { isWholesaleUser, screenName } ) => {


    const pathname = useLocation().pathname;
    const [ isLoading, setIsLoading ] = useState( false );
    const dispatch = useDispatch();

    const { newPlants, wholesalePlants, collectionPlants, carnivorousPlants } = useSelector( state => state.plants );
    let plants = checkSelectorScreenPlant( newPlants, wholesalePlants, collectionPlants, carnivorousPlants, screenName );

    const getPlants = async () => {
        if ( !validateIfNeedToReloadPlants( newPlants, wholesalePlants, collectionPlants, carnivorousPlants, screenName ) ) {
            return;
        }
        setIsLoading( true );
        await getPlantsThunk( dispatch, getUriNameByPathname( pathname ) );
        return setIsLoading( false );
    }

    useEffect( () => {
        getPlants().then( () => {
            window.scrollTo( 0, sessionStorage.getItem( 'scroll' ) || 0 );
        } );
    }, [ pathname, screenName ] );

    const renderPlantCards = () => {
        if (isLoading) {
            return [1, 2, 3, 4, 5, 6].map( ( num ) => (
                <Col key={num} xs={{ span: 21, offset: 1 }} sm={{ span: 21, offset: 1 }}
                     md={{ span: 11, offset: 1 }}
                     lg={{ span: 11, offset: 1 }} xl={{ span: 7, offset: 1 }} xxl={{ span: 5, offset: 1 }}
                >
                    <FakePlantGridComponent isLoading={isLoading}/>
                </Col>))
        }
        return plants.map( ( plant ) => (
            <Col key={plant[ '_id' ]} xs={{ span: 21, offset: 1 }} sm={{ span: 21, offset: 1 }}
                 md={{ span: 11, offset: 1 }}
                 lg={{ span: 11, offset: 1 }} xl={{ span: 7, offset: 1 }} xxl={{ span: 5, offset: 1 }}
            >
                <PlantCardComponent isLoading={isLoading} plantObj={plant} isWholesaleUser={isWholesaleUser}
                                    screen={screenName}/>
            </Col>
        ) )
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {screenName === NEW_PLANTS && !pathname.includes( 'detail-product' ) &&
                <Carousel className='new-plants-carousel' autoplay>
                    <div>
                        <img
                            src='https://res.cloudinary.com/dzju7pgki/image/upload/v1701986891/portraits/m6aeocdoaokz26vwkaif.png'
                            style={{ width: '100%', maxHeight: 780 }}
                            alt='discount'/>
                    </div>
                    <div>
                        <img
                            src='https://res.cloudinary.com/dzju7pgki/image/upload/v1701987325/portraits/chiku3lc8xbpr5mo6kd5.png'
                            style={{ width: '100%', maxHeight: 780 }}
                            alt='discount'/>
                    </div>
                </Carousel>}
            {pathname.includes( 'detail-product' ) ? <Outlet/> :
                <Row gutter={[ 16, 16 ]}>
                    {( ( plants.length === 0 ) && !isLoading ) ?
                        <EmptyComponent/> : renderPlantCards() }
                </Row>
            }
        </div>
    );
};

BasePlantGridScreen.propTypes = {
    screenName: PropTypes.string.isRequired,
    isWholesaleUser: PropTypes.bool.isRequired
};
export default BasePlantGridScreen;
