import { CARNIVOROUS, COLLECTOR, WHOLESALE } from "../constants/Constants";

const completePlantObj =
    {
        category: "",
        family: "",
        genre: "",
        species: "",
        specialFeature: "",
        description: "",
        priceCollector: [ 0 ],
        priceWholesale: [ 0 ],
        discountCollector: [ 0.0 ],
        discountWholesale: [ 0.0 ],
        timeDiscountCollector: [],
        timeDiscountWholesale: [],
        sizeCollector: [],
        sizeWholesale: [],
        quantity: 0,
        conservation: [],
        minOrder: [ 0 ],
        maxOrder: [ 0 ],
        photos: [],
        publishedDate: ""
    };


export const compareObjects = ( obj1, obj2 = completePlantObj ) => {
    const keys1 = Object.keys( obj1 );
    const keys2 = Object.keys( obj2 );

    keys2.forEach( key => {
        if ( !keys1.includes( key ) ) {
            obj1[ key ] = obj2[ key ];
        }
    } );

    return obj1;
}

export const convertToArrays = ( obj, pattern ) => Object.keys( obj )
    .filter( key => key.includes( pattern ) )
    .map( key => obj[ key ] );

export const isOnlyCollector = ( obj ) => {
    return {
        ...obj,
        priceWholesale: [ 0 ],
        discountWholesale: [ 0.0 ],
        timeDiscountWholesale: [],
        sizeWholesale: [],
        minOrder: [ 0 ],
        maxOrder: [ 0 ],
    }
}

export const isOnlyWholesale = ( obj ) => {
    return {
        ...obj,
        priceCollector: [ 0 ],
        discountCollector: [ 0.0 ],
        timeDiscountCollector: [],
        sizeCollector: [],
    }
}

export const buildFinalPlantObj = async ( obj, priceCollector, discountCollector, timeDiscountCollector, priceWholesale, discountWholesale, timeDiscountWholesale, minOrder, maxOrder ) => {
    let resultPhotos = [];
    if ( obj.photos.fileList.length > 0 ) {
        resultPhotos = await convertAllPhotos( obj.photos.fileList );
    }
    return {
        category: obj.category.trim(),
        family: obj.family.trim(),
        genre: obj.genre.trim(),
        species: obj.species.trimEnd(),
        specialFeature: obj.specialFeature.trimEnd(),
        description: obj.description?.trimEnd() || '',
        priceCollector: priceCollector.length === 0 ? [ 0 ] : priceCollector.map( price => Number( price ) ),
        priceWholesale: priceWholesale.length === 0 ? [ 0 ] : priceWholesale.map( price => Number( price ) ),
        discountCollector: discountCollector.length === 0 ? [ 0.0 ] : discountCollector.map( discount => Number( discount / 100 ) ),
        discountWholesale: discountWholesale.length === 0 ? [ 0.0 ] : discountWholesale.map( discount => Number( discount / 100 ) ),
        timeDiscountCollector: ( !timeDiscountCollector[ 0 ] ) ? [] : timeDiscountCollector.map( time => `${time[ '$M' ] + 1}/${time[ '$D' ]}/${time[ '$y' ]}` ),
        timeDiscountWholesale: ( !timeDiscountWholesale[ 0 ] ) ? [] : timeDiscountWholesale.map( time => `${time[ '$M' ] + 1}/${time[ '$D' ]}/${time[ '$y' ]}` ),
        sizeCollector: obj.sizeCollector,
        sizeWholesale: obj.sizeWholesale,
        quantity: obj.quantity,
        conservation: orderedConservation( obj.conservation ),
        minOrder: minOrder.length === 0 ? [ 0 ] : minOrder.map( min => Number( min ) ),
        maxOrder: maxOrder.length === 0 ? [ 0 ] : maxOrder.map( max => Number( max ) ),
        photos: resultPhotos.length > 0 ? resultPhotos : obj.photos,
        publishedDate: obj.publishedDate[ '$M' ] ? `${obj.publishedDate[ '$M' ] + 1}/${obj.publishedDate[ '$D' ]}/${obj.publishedDate[ '$y' ]}` : obj.publishedDate
    };
}

export const buildFinalPlantObjForUpdate = ( oldObj, newObj ) => {
    return {
        category: newObj.category.trim(),
        family: newObj.family.trim(),
        genre: newObj.genre.trim(),
        species: newObj.species.trimEnd(),
        specialFeature: newObj.specialFeature.trimEnd(),
        description: newObj.description.trimEnd() || '',
        priceCollector: oldObj.priceCollector,
        priceWholesale: oldObj.priceWholesale,
        discountCollector: oldObj.discountCollector,
        discountWholesale: oldObj.discountWholesale,
        timeDiscountCollector: oldObj.timeDiscountCollector,
        timeDiscountWholesale: oldObj.timeDiscountWholesale,
        sizeCollector: oldObj.sizeCollector,
        sizeWholesale: oldObj.sizeWholesale,
        quantity: newObj.quantity,
        conservation: orderedConservation( newObj.conservation ),
        minOrder: oldObj.minOrder,
        maxOrder: oldObj.maxOrder,
        photos: oldObj.photos,
        publishedDate: `${newObj.publishedDate[ '$M' ] + 1}/${newObj.publishedDate[ '$D' ]}/${newObj.publishedDate[ '$y' ]}`
    };

}

const orderedConservation = ( conservationArray ) => {
    const customSortOrder = [ 'light-shade', 'sun', 'shade' ];
    return conservationArray.sort( ( a, b ) => {
        const indexOfA = customSortOrder.indexOf( a );
        const indexOfB = customSortOrder.indexOf( b );

        return indexOfA - indexOfB;
    } );
}

export const buildSearchPlantObj = ( searchTerm, searchCriteria, screenName ) => {
    if ( searchTerm ) {
        return {
            searchCriteria: {
                category: null,
                family: null,
                genre: null
            },
            filterCriteria: {
                wholesale: screenName === WHOLESALE ? true : null,
                collector: screenName === COLLECTOR ? true : null,
                carnivorous: screenName === CARNIVOROUS ? true : null,
                all: screenName === 'all' ? true : null
            }
        }
    } else {

        if ( searchCriteria.genre ) {
            searchCriteria.category = null;
            searchCriteria.family = null;
        }

        if ( searchCriteria.family && !searchCriteria.genre ) {
            searchCriteria.category = null;
        }

        return {
            searchCriteria: {
                category: searchCriteria.category,
                family: searchCriteria.family,
                genre: searchCriteria.genre
            },
            filterCriteria: {
                wholesale: screenName === WHOLESALE ? true : null,
                collector: screenName === COLLECTOR ? true : null,
                carnivorous: screenName === CARNIVOROUS ? true : null,
                all: screenName === 'all' ? true : null
            }
        }
    }

}

export const convertAllPhotos = async ( photoFiles ) => {

    try {
        return await Promise.all( photoFiles.map( toImageBase64 ) );
        // Do something with the array of base64Images
    } catch ( error ) {
        console.error( error );
    }
};

export const convertOneSinglePhoto = async ( photoFile ) => {

    try {
        return await toImageBase64( photoFile );
        // Do something with the array of base64Images
    } catch ( error ) {
        console.error( error );
    }
};

const toImageBase64 = imageFile => new Promise( ( resolve, reject ) => {
    const file = imageFile.originFileObj;

    const reader = new FileReader();

    reader.readAsDataURL( file );

    reader.onload = () => {
        const base64Image = reader.result;
        resolve( base64Image );
    };
    reader.onerror = ( error ) => {
        reject( error );
    }
} );

export const getAllPhotoIndexAndReturnNext = ( filesToKeep, filesToSave ) => {
    let numbers = [];
    filesToKeep.forEach( url => {
        const number = url.split( '_' ).pop().split( '.' )[ 0 ];
        numbers.push( parseInt( number ) );
    } );
    filesToSave.forEach( file => {
        numbers.push( parseInt( file.index ) );
    } );
    const n = numbers.length + 1;
    const expectedSum = ( n * ( n - 1 ) ) / 2;
    const actualSum = numbers.reduce( ( sum, num ) => sum + num, 0 );
    return expectedSum - actualSum;
}
