import React, { useState } from 'react';
import { Avatar, Button, Col, Divider, Form, message, Row, Segmented } from "antd";
import DiscountPlantComponentScreen from "./admin/DiscountPlantComponentScreen";
import { FaUnlockAlt } from "react-icons/fa";
import { FaHouseFlag } from "react-icons/fa6";
import { findPlantById, putPlant } from "../services/PlantService";
import Search from "antd/es/input/Search";
import { COLLECTION_CHECKBOX, WHOLESALE_CHECKBOX } from "../constants/Constants";
import { buildFinalPlantObj, convertToArrays, isOnlyCollector, isOnlyWholesale } from "../utils/plantObjUtils";
import { RiPlantFill } from "react-icons/ri";
import dayjs from 'dayjs';

const DiscountAdminPlantBaseScreen = () => {

    const [ discountPlantForm ] = Form.useForm();

    const [ isLoading, setIsLoading ] = useState( false );
    const [ collectorSizePots, setCollectorSizePots ] = useState( [] );
    const [ wholesaleSizePots, setWholesaleSizePots ] = useState( [] );
    const [ collectorWholesaleOptions, setCollectorWholesaleOptions ] = useState( [] );
    const [ userSegmented, setUserSegmented ] = useState( null );
    const [ plant, setPlant ] = useState( {} );

    const dateFormat = 'YYYY-MM-DD';

    const onFinish = async ( values ) => {
        setIsLoading( true );
        const completeObject = {
            ...plant,
            ...values
        };
        const objWhole = collectorWholesaleOptions.includes( WHOLESALE_CHECKBOX ) &&
        !collectorWholesaleOptions.includes( COLLECTION_CHECKBOX ) ?
            isOnlyWholesale( completeObject ) : null;
        const objColl = !collectorWholesaleOptions.includes( WHOLESALE_CHECKBOX ) &&
        collectorWholesaleOptions.includes( COLLECTION_CHECKBOX ) ?
            isOnlyCollector( completeObject ) : null;
        const priceCollector = convertToArrays( values, 'priceCollector' );
        const discountCollector = convertToArrays( values, 'discountCollector' );
        const timeDiscountCollector = convertToArrays( values, 'timeDiscountCollector' );
        const priceWholesale = convertToArrays( values, 'priceWholesale' );
        const discountWholesale = convertToArrays( values, 'discountWholesale' );
        const timeDiscountWholesale = convertToArrays( values, 'timeDiscountWholesale' );
        const minOrder = convertToArrays( values, 'minOrder' );
        const maxOrder = convertToArrays( values, 'maxOrder' );
        const finalObj = {
            ...await buildFinalPlantObj( objWhole || objColl || completeObject, priceCollector,
                discountCollector, timeDiscountCollector, priceWholesale, discountWholesale, timeDiscountWholesale, minOrder, maxOrder
            ),
        };
        const { ok } = await putPlant( finalObj, plant[ '_id' ] );
        if ( !ok ) {
            message.error( "Ocurrio un error actualizando la planta" );
            setIsLoading( false )
            return;
        }
        message.success( "Planta Actualizada Correctamente", 4 );
        discountPlantForm.resetFields();
        setCollectorSizePots( [] );
        setWholesaleSizePots( [] );
        setCollectorWholesaleOptions([]);
        return setIsLoading( false );
    }

    const onChangeSegmented = ( value ) => {
        setUserSegmented( value );
    }

    const getPlantToUpdate = async ( plantId ) => {
        const result = await findPlantById( plantId );
        if ( !result?.ok ) {
            message.warning("No se pudo encontrar una planta con ese ID");
            return;
        }
        discountPlantForm.setFieldsValue( {
            ...result.plant,
        } );
        setPlant( result.plant );
        setUserSegmented( result.plant );
        setCollectorWholesaleOptions( checkIfPlantIsCollectorWholesale( result.plant.priceCollector[ 0 ], result.plant.priceWholesale[ 0 ] ) );
        setCollectorSizePots( result.plant.sizeCollector );
        setWholesaleSizePots( result.plant.sizeWholesale );
        loopPlantPricesAndDiscounts(
            result.plant );
        onChangeSegmented( 'discountEditPlant' );
    }

    const checkIfPlantIsCollectorWholesale = ( priceCollector, priceWholesale ) => {
        let arr = [];
        if ( priceCollector > 0 ) {
            arr = [ COLLECTION_CHECKBOX ];
        }

        if ( priceWholesale > 0 ) {
            arr = [ ...arr, WHOLESALE_CHECKBOX ];
        }
        return arr;
    }

    const loopPlantPricesAndDiscounts = ( plant ) => {
        plant.priceCollector.forEach( ( price, index ) => {
            if ( price ) {
                discountPlantForm.setFieldValue( `priceCollector${index}`, price ?? 0 );
            }
        } );
        plant.priceWholesale.forEach( ( price, index ) => {
            if ( price ) {
                discountPlantForm.setFieldValue( `priceWholesale${index}`, price ?? 0 );
            }
        } );
        plant.discountCollector.forEach( ( discount, index ) => {
            if ( discount ) {
                discountPlantForm.setFieldValue( `discountCollector${index}`, discount * 100 || 0.0 );
            }
        } );
        plant.discountWholesale.forEach( ( discount, index ) => {
            if ( discount ) {
                discountPlantForm.setFieldValue( `discountWholesale${index}`, discount * 100 || 0.0 );
            }
        } );
        plant.timeDiscountCollector.forEach( ( time, index ) => {
            if ( time ) {
                discountPlantForm.setFieldValue( `timeDiscountCollector${index}`, dayjs( time, dateFormat ) ?? '' );
            }
        } );
        plant.timeDiscountWholesale.forEach( ( time, index ) => {
            if ( time ) {
                discountPlantForm.setFieldValue( `timeDiscountWholesale${index}`, dayjs( time, dateFormat ) ?? '' );
            }
        } );
        plant.minOrder.forEach( ( minOrder, index ) => {
            if ( minOrder ) {
                discountPlantForm.setFieldValue( `minOrder${index}`, minOrder );
            }
        } );
        plant.maxOrder.forEach( ( maxOrder, index ) => {
            if ( maxOrder ) {
                discountPlantForm.setFieldValue( `maxOrder${index}`, maxOrder );
            }
        } );
    }

    const renderUserFormComponent = () => {

        if ( userSegmented === 'discountEditPlant' ) {
            return <Form
                layout='vertical'
                style={{ width: '100%' }}
                form={discountPlantForm}
                className='create-plant-form'
                onFinish={onFinish}
                scrollToFirstError>
                <h1>Aplicar Descuento a las Plantas</h1>
                <DiscountPlantComponentScreen collectorWholesaleOptions={collectorWholesaleOptions}
                                              collectorSizePots={collectorSizePots}
                                              setCollectorWholesaleOptions={setCollectorWholesaleOptions}
                                              setWholesaleSizePots={setWholesaleSizePots}
                                              setCollectorSizePots={setCollectorSizePots}
                                              wholesaleSizePots={wholesaleSizePots}
                />
                <Button htmlType='submit' loading={isLoading}>Aplicar Descuento</Button>
            </Form>
        }
        if ( userSegmented === 'discountToAllCollectorPlants' ) {
            return <h1>Hola Mundo</h1>
        }

        if ( userSegmented === 'discountToAllWholesalePlants' ) {
            return <div>Hola Mundo 2</div>
        }
        if ( userSegmented === 'discountToAllCarnivorousPlants' ) {
            return <div>Hola Mundo 2</div>
        }
        if ( userSegmented === 'removeAllDiscounts' ) {
            return <div>Hola Mundo 3</div>
        }

    }

    return (
        <Row>
            <Divider>Buscar Planta</Divider>
            <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
                <Search placeholder="Identificación Planta" onSearch={getPlantToUpdate}
                        className='form-item'
                        style={{ width: '40%' }}
                        enterButton="Buscar Planta" size="large"/>
            </Col>
            <Divider/>
            <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
                <Segmented
                    className='segmented-discount'
                    onChange={onChangeSegmented}
                    disabled={!userSegmented}
                    value={userSegmented}
                    options={[
                        {
                            label: (
                                <div style={{ padding: 4 }}>
                                    <Avatar style={{ backgroundColor: '#57acf8' }} icon={<RiPlantFill/>}/>
                                    <div>Descuento Planta</div>
                                </div>
                            ),
                            value: 'discountEditPlant',
                        },
                        {
                            label: (
                                <div style={{ padding: 4 }}>
                                    <Avatar style={{ backgroundColor: '#f56a00' }} icon={<FaUnlockAlt/>}></Avatar>
                                    <div>Descuentos Generales</div>
                                </div>
                            ),
                            value: 'generalDiscounts',
                        },
                        {
                            label: (
                                <div style={{ padding: 4 }}>
                                    <Avatar style={{ backgroundColor: '#87d068' }} icon={<FaHouseFlag/>}/>
                                    <div>Remover Descuentos</div>
                                </div>
                            ),
                            value: 'removeAllDiscounts',
                        },
                        {
                            label: (
                                <div style={{ padding: 4 }}>
                                    <Avatar style={{ backgroundColor: '#d93f3c' }} icon={<FaHouseFlag/>}/>
                                    <div>Editar Fotos</div>
                                </div>
                            ),
                            value: 'editPhotos',
                        },
                    ]}
                />
            </Col>
            <Col span={24}>
                {renderUserFormComponent()}
            </Col>
        </Row>
    );
};

export default DiscountAdminPlantBaseScreen;
