import { Alert } from "antd";
import React from "react";
import { FaUserAlt } from "react-icons/fa";
import { HiBuildingStorefront } from "react-icons/hi2";
import { MdEmail, MdOutlinePhoneAndroid } from "react-icons/md";
import { TbUserPentagon } from "react-icons/tb";

export const descriptionUser = ( userObj ) => {
    return Object.keys(userObj).length > 0 ?
        [
            {
                label:  <span style={{fontSize: 14}}><FaUserAlt size={20} color='#D6249F'/>&nbsp;&nbsp;&nbsp;Nombre Completo</span>,
                children: <b style={{fontSize: 14}}>{userObj.name}</b>,
                span: { xs: 2, sm: 2, md: 1, lg: 1, xl: 1, xxl: 1 }
            },
            {
                label: <span style={{fontSize: 14}}><HiBuildingStorefront size={20} color='#D6249F'/>&nbsp;&nbsp;&nbsp;Compañía</span>,
                children: <b style={{fontSize: 14}}>{userObj.company}</b>,
                span: { xs: 2, sm: 2, md: 1, lg: 1, xl: 1, xxl: 1 }
            },
            {
                label: <span style={{fontSize: 14}}><MdEmail size={20} color='#D6249F'/>&nbsp;&nbsp;&nbsp;Correo</span>,
                children: <b style={{fontSize: 14}}>{userObj.email}</b>,
                span: { xs: 2, sm: 2, md: 1, lg: 1, xl: 1, xxl: 1 }
            },
            {
                label: <span style={{fontSize: 14}}><MdOutlinePhoneAndroid size={20} color='#D6249F'/>&nbsp;&nbsp;&nbsp;Teléfono</span>,
                children: <b style={{fontSize: 14}}>{userObj.phone}</b>,
                span: { xs: 2, sm: 2, md: 1, lg: 1, xl: 1, xxl: 1 }
            },
            {
                label: <span style={{fontSize: 14}}><TbUserPentagon size={20} color='#D6249F'/>&nbsp;&nbsp;&nbsp;Es Usuario Por Mayor</span>,
                children: userObj.isWholesaleUser ?
                    <Alert message="Usuario Mayorista" type="success" showIcon/> :
                    <Alert message="El usuario NO es mayorista" type="warning" showIcon/>,
                span: 2
            }
        ] :
        [
            {
                label: 'Nombre Completo',
                children: '',

            },
            {
                label: 'Compañía',
                children: '',
            },
            {
                label: 'Correo',
                children: '',
            },
            {
                label: 'Teléfono',
                children: '',
            },
            {
                label: 'Es Usuario Por Mayor',
                children: '',
                span: 2
            },
        ];
}

export const buildFinalUserObj = ( userObj, values, addresses ) => {
    return {
        name: userObj.name,
        email: userObj.email,
        phone: userObj.phone,
        isWholesaleUser: values.isWholesaleUser,
        company: values.company,
        addresses,
    }
}
